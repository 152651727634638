import React from 'react'

import Layout from 'components/global/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PageHeader from 'components/shared/pageHeader'
import { media } from 'utils/Media'


const StyledHandbook  = styled.section`
    width: 100%;
    div {
        width: 90%;
        margin: 0 auto;
        max-width: 1000px;
        padding: 3rem 0;
        h1 {    
            font-size: 3rem;
            margin-bottom: 1rem;
        }
        h2 { 
            font-size: 1rem;
            @media ${media.md} {
                font-size: 1.5rem;
            }
            margin-bottom: 1rem;

        }
        ul {
            padding-left: 22px!important;
        }
        a {
          &:hover {
            color: var(--main);
          }
        }
    }
`

const meta = {
    title:
        'The Mousetrap Official Site - The world’s longest running play in the West End.',
    description:
        'The world’s longest running play is back onstage now in the West End. Performances now on sale until 2025! – book direct and book with confidence with no quibble exchanges.',
}

const HandbookPage = ({data}) => {
    const mob = data.mobHeaderImg
    const desk = data.deskHeaderImg
    return (
        <Layout title={meta.title}>
        <PageHeader mobileHeader={mob} desktopHeader={desk} />  
        <StyledHandbook>
            <div>
                <h1>Page Handbook</h1>
                <p><a href="https://docs.google.com/document/d/1plbieTV7WsjbIFHF_xGsx5NOF9EMe97n/edit">Wellbeing and Mental Health Resources</a></p>
                <br />
<h2>INTRODUCTION</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.sfxj6l64xs7b">1. Introduction</a></li>
</ul>
<h2>OPERATIONAL POLICIES AND PROCEDURES</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.ayunahoonuj3">2. Adverse Weather and Travel Disruption</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.g9jhyafg2jn4">3. Compassionate Leave</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.sc4usbq5z4e8">4. Dress Code</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.1tixwh229k88">5. Recruitment, Promotion and Appraisal</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.6j0d4tz1imcx">6. Smoking</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.t0vx0kcfj6ge">7. Substance Misuse</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.c2jexyamx8f7">8. Time Off for Jury Service or Court Attendance</a></li>
</ul>
<h2>EQUALITY, BULLYING AND HARASSMENT</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.7zj4xkmvjhzf">9. Equality</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.9sjd3go7jyj4">10. Dignity at Work Policy (incorporating Bullying and Harassment)</a></li>
</ul>
<h2>DISCIPLINARY AND GRIEVANCE/ PERFORMANCE AND CAPABILITY</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.hbgyvtyjwvxc">11. Disciplinary Procedure (incorporating Disciplinary Rules)</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.1wlu79z55hd5">12. Grievance</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.wxemw512rloy">13. Performance and Capability at Work</a></li>
</ul>
<h2>FAMILY AND DEPENDENTS POLICIES AND PROCEDURES</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.m1n9yc2w7c8b">14. Antenatal Appointments (Expectant Mothers or Companions)</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.1zylfr8tpy66">15. Flexible Working</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.jkrniimfplxq">16. Maternity</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.8u9536t1je3d">17. Parental Leave</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.8hyd7si6mj9">18. Paternity Leave</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.8a7qh89m8eay">19. Shared Parental Leave</a></li>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.wg3d0cbvo0qk">20. Time Off for Dependants</a></li>
</ul>
<h2>SICKNESS AND HEALTH</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.w19demxayeku">21. Sickness and Attendance</a></li>
</ul>
<h2>ANTI-BRIBERY AND CORRUPTION</h2>
<ul>
<li aria-level="1"><a href="https://docs.google.com/document/d/1NuRgzCKjpNPx1u2L5QNzP57xUPrLMr0x/edit#bookmark=id.wrj07gnshi0s">22. Anti-Bribery and Corruption</a></li>
</ul>
            </div>
            <div>
            <p><strong>MT SPV LIMITED</strong></p>
<p><strong>STAFF HANDBOOK</strong></p>
<p>Contents</p>
<p><a href="#_heading=h.30j0zll">1 Introduction 3</a></p>
<p><a href="#_heading=h.1fob9te">2 Adverse weather and travel disruption 6</a></p>
<p><a href="#_heading=h.111kx3o">3 Compassionate leave 9</a></p>
<p><a href="#_heading=h.48pi1tg">4 Dress code 11</a></p>
<p><a href="#_heading=h.279ka65">5 Recruitment, promotion and appraisal 12</a></p>
<p><a href="#_heading=h.3u2rp3q">6 Smoking 14</a></p>
<p><a href="#_heading=h.1f7o1he">7 Substance misuse 15</a></p>
<p><a href="#_heading=h.sabnu4">8 Time off for jury service or court attendance 18</a></p>
<p><a href="#_heading=h.1tdr5v4">9 Equality 21</a></p>
<p><a href="#_heading=h.1vc8v0i">10 Dignity at work policy (incorporating bullying and harassment) 23</a></p>
<p><a href="#_heading=h.4fbwdob">11 Disciplinary Procedure (incorporating Disciplinary Rules) 28</a></p>
<p><a href="#_heading=h.2uh6nw4">12 Grievance 34</a></p>
<p><a href="#_heading=h.11bux6d">13 Performance and capability at work 36</a></p>
<p><a href="#_heading=h.1gpiias">14 Antenatal appointments (expectant mothers or companions) 41</a></p>
<p><a href="#_heading=h.24ds4cr">15 Flexible working 43</a></p>
<p><a href="#_heading=h.1y1nskl">16 Maternity 45</a></p>
<p><a href="#_heading=h.349n5n2">17 Parental leave 49</a></p>
<p><a href="#_heading=h.2kicxvv">18 Paternity leave 52</a></p>
<p><a href="#_heading=h.20r2q4o">19 Shared parental leave 55</a></p>
<p><a href="#_heading=h.1iya7iv">20 Time off for dependants 60</a></p>
<p><a href="#_heading=h.3j6nifh">21 Anti-bribery and corruption 62</a></p>
<p><a href="#_heading=h.16a1jyj">22 Sickness and attendance 65</a></p>
<h1 id="introduction">Introduction</h1>
<p>This handbook provides information about working for <strong>MT SPV</strong> <strong>Limited.</strong> (the Employer). This handbook applies to all employees and (where expressly stated) will also apply to all workers and self-employed contractors. All employees, workers and self-employed contractors based at venues that are not owned or operated by the Employer will be expected to familiarise themselves with and adhere to the venue-specific policies and procedures, including but not limited to those relating to health &amp; safety matters and smoking policies.</p>
<p>Everyone will receive a copy of the handbook and is expected to familiarise themselves with its contents. If you do not understand any aspect of this handbook, please ask your Line Manager for clarification.</p>
<p>This handbook is meant to assist you in understanding the policies, general rules, and operating standards that apply to all company employees. It provides an overview of the company&#39;s policies and procedures. However, it&#39;s important to note that unless expressly stated otherwise, the handbook is not a legally binding part of your employment contract. In any dispute between you and the company, the terms of your employment contract will take precedence.</p>
<p>The content of this handbook will be reviewed regularly, and you will be notified of updates. Where necessary, an updated version of this handbook may be reissued.</p>
<h2 id="statement-of-equality">Statement of equality</h2>
<ul>
<li><ul>
<li><ol>
<li>At MT SPV Ltd, we are steadfast in leveraging the talents and resources of all our employees and staff members. We aim to foster a healthy environment that encourages outstanding and productive performances across our operations. We uphold a policy of treating all employees, workers, and job applicants with equal respect and opportunity No employee or prospective employee shall receive less favourable treatment due to any &#39;protected characteristic&#39;, including:<pre><code> <span class="hljs-number">1</span>.  Age (<span class="hljs-keyword">or</span> perceived age)
 <span class="hljs-number">2</span>.  Disability (past <span class="hljs-keyword">or</span> present)
 <span class="hljs-number">3</span>.  Gender reassignment
 <span class="hljs-number">4</span>.  Marriage <span class="hljs-keyword">or</span> civil partnership <span class="hljs-built_in">status</span>
 <span class="hljs-number">5</span>.  Race, colour, nationality, ethnic <span class="hljs-keyword">or</span> national origins
 <span class="hljs-number">6</span>.  Religion <span class="hljs-keyword">or</span> belief
 <span class="hljs-number">7</span>.  Sex
 <span class="hljs-number">8</span>.  Sexual <span class="hljs-built_in">orientation</span>
 <span class="hljs-number">9</span>.  Trade <span class="hljs-built_in">union</span> membership (<span class="hljs-keyword">or</span> non-membership)
 <span class="hljs-number">10</span>. Part-<span class="hljs-built_in">time</span> <span class="hljs-keyword">or</span> fixed-term employment <span class="hljs-built_in">status</span>
</code></pre><ol>
<li>We are dedicated to cultivating, valuing, and managing diversity, striving for a workforce that mirrors the diverse communities from which we draw our talent. These commitments to equality of opportunity and non-discrimination extend to the way in which our staff interacts with customers, business partners, and visitors.</li>
</ol>
</li>
</ol>
</li>
</ul>
</li>
</ul>
<h2 id="anti-harassment-and-bullying-summary">Anti Harassment and Bullying Summary</h2>
<ul>
<li><ul>
<li><ol>
<li>At MT SPV Limited, we are dedicated to maintaining a workplace free from harassment and bullying, ensuring all staff members are treated—and treat others—with dignity and respect. We commit to creating an environment where all staff feel safe and supported in reporting any incidents of bullying and harassment.<ol>
<li>Comprehensive details, policies, and procedures regarding our anti-harassment and bullying stance can be found within the main body of this handbook.</li>
</ol>
</li>
</ol>
</li>
</ul>
</li>
</ul>
<h2 id="using-this-handbook">Using this handbook</h2>
<ul>
<li><ul>
<li><ol>
<li>This handbook is accessible online, with individual policies and procedures organised into themed sections for easy navigation. You can access these by clicking on the relevant links:<pre><code> 1.  [<span class="hljs-string">Operational/General Policies and Procedures</span>](<span class="hljs-link">#bookmark=id.p254yc445vly</span>)
 2.  [<span class="hljs-string">Equality, Bullying and Harassment</span>](<span class="hljs-link">#bookmark=id.ctqx74vdbz5e</span>)
 3.  [<span class="hljs-string">Disciplinary and Grievance | Performance and Capability</span>](<span class="hljs-link">#bookmark=id.e0qr51nj7qze</span>)
 4.  [<span class="hljs-string">Family and Dependents</span>](<span class="hljs-link">#bookmark=id.v9xjlfwhkfj</span>)
 5.  ICT and Social Media
 6.  Anti-bribery and corruption and anti-slavery
 7.  Data Protection
 8.  Sickness and Attendance | Health, Safety and Wellbeing
 9.  Child Protection
</code></pre><ol>
<li>Please take the time to familiarise yourself with these policies and procedures. While comprehensive, they are designed to guide you on what we expect from you and what you can expect from us. With these guidelines, and our culture of openness and understanding, we are confident that you will enjoy working with us and contribute to bringing outstanding theatre to the public.</li>
<li>Should you have any questions about the content of this handbook, or if you require a hard copy of either the entire handbook or any specific policy, please contact us at <a href="&#109;&#x61;&#105;&#x6c;&#x74;&#111;&#x3a;&#x61;&#x64;&#109;&#105;&#110;&#64;&#116;&#x68;&#101;&#x6d;&#x6f;&#x75;&#115;&#x65;&#x74;&#x72;&#x61;&#x70;&#46;&#x63;&#x6f;&#x6d;">&#x61;&#x64;&#109;&#105;&#110;&#64;&#116;&#x68;&#101;&#x6d;&#x6f;&#x75;&#115;&#x65;&#x74;&#x72;&#x61;&#x70;&#46;&#x63;&#x6f;&#x6d;</a>.</li>
</ol>
</li>
</ol>
</li>
</ul>
</li>
</ul>
<h1 id="operational-policies-and-procedures">OPERATIONAL POLICIES AND PROCEDURES</h1>
<h2 id="-1-adverse-weather-and-travel-disruption-"><strong>1. Adverse Weather and Travel Disruption</strong></h2>
<ul>
<li><ol>
<li><strong>Introduction</strong><ol>
<li>MT SPV Limited recognises that severe weather or public transport disruptions can make it challenging for staff to commute to their usual place of work. A flexible approach to work arrangements may be necessary during such times.</li>
<li>Employer Expectations:<ol>
<li>Attend work unless on authorised leave (such as annual holiday, sick leave, or maternity leave).</li>
<li>to make reasonable efforts to get to work in all circumstances;</li>
<li>Explore all possible alternative modes of transport, including walking or cycling if safe and feasible, when usual travel arrangements are disrupted.</li>
<li>Collaborate with your line manager in advance if travel difficulties are predictable.</li>
</ol>
</li>
<li>We are committed to ensuring the health and safety of our workforce. You are not expected to take unnecessary risks when travelling. However, minimal disruption to our operations is imperative, and we expect all employees, especially those involved in performances (both backstage and onstage), to make every reasonable effort to attend work.</li>
<li>This policy applies during severe weather events (e.g., heavy snow, extreme cold, or flooding) or significant public transport disruptions (e.g., due to industrial action or major incidents). This is not an exhaustive list, and the policy extends to any situation that could impact employees&#39; ability to attend or remain at work.</li>
<li>This policy is not intended to deal with absence related to sickness, annual leave, maternity/paternity/adoption leave, parental leave or time off for dependants.</li>
<li>This policy is for guidance only and does not form part of your contract of employment. It may be amended at any time by the Employer.</li>
</ol>
</li>
<li><strong>Absences</strong><ol>
<li>If despite reasonable efforts, you cannot attend work due to severe weather or travel disruption, your line manager will consult with you to determine the most appropriate course of action, considering your commute distance, mode of transport, safety, and business needs. Options may include:<ol>
<li>Emergency leave with pay (basic salary) for up to two consecutive days.</li>
<li>take the time you have been absent from work as annual leave. Where your annual leave entitlement has been used up for the current holiday year, up to four days&#39; leave may be brought forward from the following year&#39;s entitlement in order to cover some or all of this absence;</li>
<li>Making up lost time at a later date, if feasible.</li>
<li>Taking authorised unpaid leave.</li>
<li>Working from home or another suitable location, if practical.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Lateness</strong><ol>
<li>If adverse conditions delay you significantly (half your daily working hours or more), your line manager may instruct you to:<ol>
<li>take the time as annual leave;</li>
<li>take the time as unpaid leave.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Disruption arising during work hours</strong><ol>
<li>If severe conditions begin during work hours, your line manager will decide whether it is appropriate for you to leave early, based on your individual circumstances. If approved, you will receive your usual pay.</li>
<li>The Employer may require you to take work with you to complete at home if appropriate.</li>
</ol>
</li>
<li><strong>Temporary Workplace Closure</strong><ol>
<li>In the rare event that we must temporarily close our workplace, you will be notified as promptly as possible, and you will receive pay for the closure period. Those who can work remotely should do so.</li>
</ol>
</li>
<li><strong>Stranded Employees</strong><ol>
<li>You must inform your line manager of any delays or absences due to adverse conditions as soon as possible, adhering to the timescales set in our Sickness and Attendance Policy. Monitor and report any changes in conditions that might affect your ability to work as required.</li>
</ol>
</li>
<li><strong>Communication</strong><ol>
<li>You must inform your line manager of any delays or absences due to adverse conditions as soon as possible, adhering to the timescales set in our Sickness and Attendance Policy. Monitor and report any changes in conditions that might affect your ability to work as required.</li>
</ol>
</li>
<li><strong>Disciplinary action</strong><ol>
<li>If this policy does not apply, you are expected to attend work, unless you have another valid reason for absence, such as sickness.</li>
<li>All other lateness or unauthorised absence may give rise to disciplinary action for misconduct.</li>
<li>Failure to follow the requirements and procedures set out in this policy may also give rise to disciplinary action.</li>
<li>When considering disciplinary action, the Employer will take into account all circumstances, including (but not limited to) your distance to travel, local conditions and efforts made by other employees in comparable circumstances.</li>
<li>For further details in relation to disciplinary action, see the Employer&#39;s disciplinary procedure.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-compassionate-leave-"><strong>Compassionate Leave</strong></h2>
<ul>
<li><ol>
<li><strong>Introduction</strong><ol>
<li>MT SPV Limited provides compassionate leave to support employees coping with the death of a close relative, enabling them to make necessary arrangements and attend the funeral. This leave may also be granted if a close relative is seriously or critically ill. This policy outlines the approach the Employer will take regarding compassionate leave and the steps you should follow to request such leave.</li>
<li>This policy applies to all employees, officers, agency workers, consultants, contractors, volunteers, and casual workers. Please note that some aspects of this policy may overlap with the Employer’s Dependants leave policy, particularly in circumstances that may entitle you to leave under both policies. In such cases, you may only exercise your right under one of the policies.</li>
<li>This policy does not form part of any contract of employment and the Employer may amend it at any time.</li>
</ol>
</li>
<li><strong>Leave entitlement</strong><ol>
<li>Compassionate leave is a paid entitlement that allows you to manage personal distress and related practical matters, primarily following the death of a family member. Recognising that bereavement affects everyone differently, the following guidelines indicate the minimum leave provided:<ol>
<li>Up to five working days of paid leave per 12-month period for the death of a close relative.</li>
<li>In exceptional circumstances, up to three days&#39; paid compassionate leave for someone outside your immediate family. Circumstances include (but are not limited to) where you are responsible for funeral arrangements or have to travel abroad to attend the funeral.</li>
</ol>
</li>
<li>Close relatives include your spouse or partner, children (including adopted children and legal wards), parents, siblings, in-laws, grandparents, grandchildren, and your children’s spouses.</li>
</ol>
</li>
<li><strong>Requesting compassionate leave</strong><ol>
<li>Due to the nature of these circumstances, we understand you might not always be able to request compassionate leave in advance. Please inform your line manager of your need for leave as soon as possible, or at the latest, on the first day of absence. This notification can be made on your behalf if necessary.</li>
<li>You are not required to take your leave days consecutively, but you should coordinate the scheduling of your leave days with your line manager wherever possible.</li>
<li>The Employer will only refuse compassionate leave requests in exceptional circumstances. Should a request be denied, you will receive a written explanation, and you may appeal the decision through the Employer’s Grievance Procedure.</li>
</ol>
</li>
<li><strong>Annual leave and unpaid leave</strong><ol>
<li>You may request short-notice annual leave to extend your compassionate leave. Alternatively, the Employer may grant further unpaid leave at its discretion. All requests should follow the usual procedure for requesting leave.</li>
</ol>
</li>
<li><strong>Return to work</strong><ol>
<li>If you are unable to return to work after compassionate leave, discuss potential options with your line manager, such as taking additional annual leave or a further period of unpaid leave.</li>
</ol>
</li>
<li><strong>Health and safety</strong><ol>
<li>Bereavement can impact your ability to perform duties safely. If concerned, you should discuss your capacity to work with your line manager. The Employer may require a medical assessment before you resume full duties.</li>
</ol>
</li>
<li><strong>Culture and diversity</strong><ol>
<li>The Employer recognises that cultural responses to death vary significantly. Line managers will accommodate any specific cultural or religious observances that affect work absence, and you should inform your line manager of any such requirements promptly. Managers unsure of cultural practices should seek guidance from the bereaved employee or a representative from their cultural group.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-dress-code-"><strong>Dress Code</strong></h2>
<ul>
<li><ul>
<li><ol>
<li><p>The goal of the Employer&#39;s dress code policy is to ensure that all employees present a professional and positive image through their attire and personal appearance while at work.</p>
<ol>
<li>The Employer is the definitive authority on what is considered appropriate attire under this policy. As this dress code policy does not cover every possible scenario, employees are expected to exercise common sense and adhere to the underlying principles of the policy. If there is any uncertainty about the appropriateness of an outfit or aspect of appearance, employees should seek advice from their line manager.</li>
<li>All employees are required to familiarize themselves with and adhere to this dress code policy. Please note, this policy is subject to change at the Employer’s discretion and does not form part of the employment contract.</li>
<li>Failure to comply with this dress code policy may result in an employee being required to change their attire or being sent home. Repeated or serious non-compliance with the rules in this policy could lead to disciplinary action under the Employer&#39;s disciplinary procedure.</li>
</ol>
</li>
<li><p><strong>Policy</strong></p>
<ol>
<li>Employees not required to wear a uniform should dress in a manner that is professionally suitable for the Employer’s business environment.</li>
<li>Employees who interact with clients or customers must wear attire that is appropriately professional. Those not in direct contact with clients or customers may wear business casual attire, provided it aligns with the aims of this policy.</li>
<li>Footwear should be safe, smart, and suitable for the workplace, considering health and safety requirements.</li>
<li>Headscarves or other headwear worn for religious purposes are allowed unless they interfere with health and safety standards.</li>
</ol>
</li>
<li><strong>Uniform</strong><ol>
<li>Employees issued a uniform by the Employer are expected to wear it during working hours, unless otherwise directed by their line manager. Employees are responsible for keeping their uniforms clean, tidy, and in good condition. They must report any damage or wear to their line manager to arrange for a replacement if necessary. All uniform items must be returned to the Employer upon termination of employment.</li>
</ol>
</li>
<li><strong>Protective clothing</strong><ol>
<li>Employees must wear protective clothing and equipment as required by law or Employer rules. This includes items owned by the employee or provided by the Employer, as applicable.</li>
</ol>
</li>
<li><strong>Non-Compliance</strong><ol>
<li>Failure to adhere to the dress code policy may result in the employee being asked to modify their attire or, in some cases, being sent home. Repeated or serious violations of this policy could lead to disciplinary action under the Employer&#39;s disciplinary procedure.</li>
</ol>
</li>
</ol>
</li>
</ul>
</li>
</ul>
<h2 id="-recruitment-promotion-and-appraisal-"><strong>Recruitment, Promotion and Appraisal</strong></h2>
<ul>
<li><ol>
<li><strong>Recruitment and promotion</strong><ol>
<li>Applicants are assessed objectively based on their merits, in alignment with our Equal Opportunities Policy.</li>
<li>Any person involved in the interview or selection process whose impartiality is compromised in any way in relation to an applicant must declare it immediately to their line manager and, if so required, withdraw from the interview/selection process either completely or in relation to that applicant.</li>
</ol>
</li>
<li><strong>Advertisements</strong><ol>
<li>Vacancies will be advertised in appropriate forums, which may be online only. Advertisements must avoid any potentially discriminatory requirements based on age, sex, race, religion, sexual orientation, disability, or trade union membership. This includes direct discrimination (explicit exclusion based on prohibited grounds), indirect discrimination (requirements that disproportionately affect certain groups), and failure to make necessary adjustments for disabilities. In certain cases, specific protected characteristics may be a genuine occupational requirement, provided this is a proportionate means of achieving a legitimate aim.</li>
</ol>
</li>
<li><strong>Applications and decisions</strong><ol>
<li>At each stage of the process, applicants should be told:<ol>
<li>when, approximately, they can expect to hear whether their application is to progress to the next stage of the recruitment process; and</li>
<li>what the next stage will involve.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Interviews</strong><ol>
<li>Before conducting an interview, interviewers should:<ol>
<li>identify the requirements of the position and the (objective) criteria they will use to select candidates for the position;</li>
<li>avoid choosing criteria such as a particular level of qualifications or experience where it is possible that an applicant without such qualification or experience may be able to meet the requirements of the position in a different way.</li>
</ol>
</li>
<li>At interview:<ol>
<li>each question asked should be directly relevant to one or more of the identified selection criteria for the position;</li>
<li>no questions should be asked about an applicant&#39;s personal circumstances, even in small talk;</li>
<li>no assumptions should be made about an applicant&#39;s willingness to undertake, or suitability for, the position.</li>
</ol>
</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-smoking-"><strong>Smoking</strong></h2>
<ul>
<li><ol>
<li><strong>Purpose and scope</strong><ol>
<li>The Employer is committed to ensuring the health, safety, and welfare of all employees while at work. Smoking tobacco not only harms the health of the smoker but also impacts others through passive smoking and can lead to increased absenteeism affecting our business operations.</li>
</ol>
</li>
<li><strong>Policy</strong><ol>
<li>Smoking tobacco or any other substance is strictly prohibited outside designated smoking areas on any of our premises or any premises being used by us, including grounds and car parks. An exception is made for performers during performances or dress rehearsals when smoking is part of the stage action. While the use of e-cigarettes is permitted, it must comply with the specific policies of the venue or premises you are based in.</li>
<li>Employees who smoke or use e-cigarettes may take reasonable breaks throughout the day, adhering to this policy.</li>
</ol>
</li>
<li><strong>Procedure</strong><ol>
<li>An employee found smoking on the Employer&#39;s premises or premises being used by the Employer from time to time outside designated areas will be committing a serious offence and will be subject to disciplinary action. Where the use of e-cigarettes is prohibited by a specific venue and an employee is found to be in breach of that policy, they will also be subject to disciplinary action. In such a case the Employer will follow its disciplinary procedure. Smoking tobacco in any smoke-free area of Employer premises is also a criminal offence, for which you may be liable on summary conviction to pay a fine.</li>
<li>Employees found to be taking smoking breaks that are unreasonable in number or duration will also be subject to disciplinary action.</li>
<li>Employees have the right to report breaches of this policy. Any such reports will be treated in confidence where possible and any employee making such a report will not suffer any detriment as a result of such a report.</li>
<li>Line managers are responsible for ensuring that the policy is adhered to by all members of staff.</li>
<li>Any employee who needs assistance to adapt to the smoking policy is encouraged to come forward and ask for assistance.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-substance-misuse-"><strong>Substance misuse</strong></h2>
<ul>
<li><ol>
<li><strong>Introduction</strong><ol>
<li>The Employer is committed to providing a healthy, safe, and productive work environment. Recognising the significant impact of alcohol and drug use on safety and job performance, the company strives to maintain a workplace free from the inappropriate use of substances. Recognising that alcohol or drug problems can sometimes constitute an illness, this policy aims to prevent and address issues arising from substance misuse in the workplace.</li>
</ol>
</li>
<li><strong>Confidentiality</strong><ol>
<li>While we strive to maintain confidentiality regarding issues of substance misuse, it may be necessary in certain circumstances to inform the employee’s line manager about their situation.</li>
</ol>
</li>
<li><strong>Policy objectives</strong><ol>
<li>The objectives of this policy are to:<ol>
<li>Educate all employees on the risks associated with alcohol and drug use.</li>
<li>Encourage employees to seek help early if they suspect or know they have a problem with alcohol or drugs.</li>
<li>Provide guidance and support to employees in accessing specialist agencies for help.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Policy application</strong><ol>
<li>This policy applies to all employees, including full-time, part-time, agency staff, contractors, and consultants. It encompasses:<ol>
<li>substances covered by the Misuse of Drugs Act 1971 (referred to as &#39;controlled drugs&#39;);</li>
<li>prescribed and over-the-counter drugs;</li>
<li>solvents and any other intoxicating substances.</li>
</ol>
</li>
<li>In addition to the Employer&#39;s employees, this policy shall be observed by all agency staff, contractors, consultants and any other individual working for, at or on behalf of, the Employer.</li>
</ol>
</li>
<li><strong>Disciplinary rules</strong><ol>
<li>The following standards are set to maintain workplace integrity and safety:<ol>
<li>Consumption of alcohol or use of drugs during or before work is prohibited if it could adversely affect work performance, except under circumstances such as customer entertainment where alcohol consumption is appropriate.</li>
<li>The dispensation, distribution, possession, use, sale, or purchase of controlled drugs on company premises is strictly prohibited and will be reported to the police.</li>
<li>Employees prescribed medication that may impair their work ability should discuss their situation with their manager immediately.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Medical examination</strong><ol>
<li>If substance misuse is suspected, the employer may require the employee to undergo a medical examination to determine if an underlying problem exists. Depending on the findings, disciplinary action may be taken, or the employee may be suspended with pay to allow time for treatment and rehabilitation.</li>
</ol>
</li>
<li><strong>Monitoring this policy</strong><ol>
<li>The employer reserves the right to conduct drug and alcohol testing after incidents or when there is reasonable suspicion of substance misuse. Employees must consent in writing to such tests. Refusal to comply may result in disciplinary action.</li>
</ol>
</li>
<li><strong>Identifying the need for referral</strong><ol>
<li>Employees are encouraged to voluntarily seek help for drug or alcohol problems. Early self-referral will be treated confidentially and supportively. If management identifies a potential problem based on work performance, an interview will be arranged to discuss possible solutions and disciplinary consequences if the behaviour continues.</li>
</ol>
</li>
<li><strong>Treatment Programmes</strong><ol>
<li>Employees participating in recovery programs may be placed on unpaid leave if their condition poses a safety risk or could lead to recurrence of substance misuse. If an employee discontinues treatment, management will assess whether disciplinary action is necessary.</li>
</ol>
</li>
<li><strong>Organisational responsibility</strong><ol>
<li>Management is responsible for promoting this policy and ensuring its effective implementation. They should also be vigilant for signs of substance misuse and handle cases sensitively and in accordance with this policy</li>
</ol>
</li>
<li><strong>Employee Responsibility</strong><ol>
<li>Employees are expected to manage their alcohol and drug use responsibly and support colleagues dealing with substance misuse issues.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-time-off-for-jury-service-or-court-attendance-"><strong>Time Off for Jury Service or Court Attendance</strong></h2>
<ul>
<li><ol>
<li><strong>Introduction</strong><ol>
<li>The Employer recognises the legal obligation of employees summoned for jury service and is committed to supporting staff during their civic duties. This policy also applies to other instances when you may be required to attend court.</li>
</ol>
</li>
<li><strong>Jury service</strong><ol>
<li>On receiving a summons to serve on a jury you must:<ol>
<li>Inform your employer immediately.</li>
<li>Provide details of the court date, the expected duration, and the location.</li>
<li>Submit the Confirmation of Jury Service letter and the Certificate of Loss of Earnings form.</li>
<li>Formally request leave, which will be granted unless an exemption or deferral is obtained.</li>
</ol>
</li>
<li>Salary payments will cease during jury service; however, you can claim an allowance for lost earnings from the Courts and Tribunals Service. Please forward the Certificate of Loss of Earnings to your employer for completion.</li>
<li>Claim any travel or subsistence expenses directly from the court, as these do not affect your pay.</li>
<li>If you are not needed for jury duty on a scheduled day or if jury service ends earlier than anticipated, you must:<ol>
<li>Notify your employer and return to work immediately. Failure to do so may result in disciplinary action.</li>
<li>Ensure any leave requests are updated or cancelled accordingly.</li>
</ol>
</li>
<li>If you believe you have not been granted reasonable time off for jury service, you should initially address this through the Employer’s Grievance Procedure.</li>
</ol>
</li>
<li><strong>Other court attendance</strong><ol>
<li>Witness to a crime:<ol>
<li>If you are called as a witness to a crime, you will be granted time off, which will be unpaid.</li>
<li>Claims for loss of earnings, travel, and subsistence should be handled through the court system, similar to jury service.</li>
</ol>
</li>
<li>Character witness:<ol>
<li>You may be granted reasonable time off, at the employer’s discretion, to serve as a character witness.</li>
<li>This time off will be unpaid, and any claims for lost earnings and/or expenses should be managed through the relevant solicitor.</li>
</ol>
</li>
<li>Court appearance on your own behalf:<ol>
<li>You are required to use annual leave or adjust your work hours to accommodate your court appearance.</li>
<li>If you are unable to adjust work hours or lack sufficient annual leave, authorised unpaid leave may be granted.</li>
</ol>
</li>
</ol>
</li>
</ol>
</li>
</ul>
<h1 id="equality-bullying-and-harassment">EQUALITY, BULLYING AND HARASSMENT</h1>
<h2 id="-equality-"><strong>Equality</strong></h2>
<ul>
<li><ol>
<li><strong>Introduction</strong><ol>
<li>The Employer is committed to promoting equality and equal opportunities within the workplace.</li>
<li>This document outlines our policy designed to utilise the diverse talents of all employees and to create a healthy work environment conducive to productive operations.</li>
<li>This policy is subject to amendments and does not form part of the employment contract.</li>
</ol>
</li>
<li><strong>Training and Development</strong><ol>
<li>Key managers and supervisors responsible for maintaining equality and equal opportunities will receive necessary training, which may be updated periodically.</li>
<li>Other staff members may also be required to attend mandatory training sessions to foster an inclusive work environment.</li>
</ol>
</li>
<li><strong>Statement of principle</strong><ol>
<li>&quot;The Employer is dedicated to treating all employees, workers, and job applicants equally.</li>
<li>No individual will face discrimination due to any protected characteristic, including but not limited to: age, disability, gender reassignment, marital status, race, religion, sex, sexual orientation, trade union membership, or employment status.</li>
<li>We aim to create a workforce representative of the communities we serve, and uphold principles of equality in how we treat customers, business partners, and visitors.&quot;</li>
</ol>
</li>
<li><strong>Personal Liability</strong><ol>
<li>Employees are expected to support these objectives and are reminded that they can be held personally liable for discrimination against colleagues or job applicants.</li>
</ol>
</li>
<li><strong>Forms of Discrimination</strong><ol>
<li>Discrimination can occur in various forms:<ol>
<li>Direct Discrimination: Treating someone less favourably due to a protected characteristic.</li>
<li>Indirect Discrimination: Implementing policies that, while uniform, adversely affect individuals with protected characteristics.</li>
<li>Victimisation: Penalising someone for asserting their rights against discrimination.</li>
<li>Harassment: Engaging in unwanted conduct related to a protected characteristic that violates an individual’s dignity or creates an offensive environment.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Responsibility and Enforcement</strong><ol>
<li>All employees have a personal responsibility to apply this equality policy practically, extending to the treatment of job applicants, current and former employees, customers, and visitors.</li>
<li>This policy applies both within the workplace and in any work-related context, such as business trips, social events, or when representing the company publicly.</li>
<li>Managers and supervisors have a special responsibility for the practical application of this policy, particularly in recruitment, selection, promotion, and training activities.</li>
</ol>
</li>
<li><strong>Complaints and Grievances</strong><ol>
<li>The Employer&#39;s Grievance Procedure is available for any employee who feels they have been discriminated against.</li>
<li>Similarly, complaints of harassment and bullying can be addressed through the procedures outlined in our harassment and bullying policy.</li>
<li>These complaints will be handled with seriousness, confidentiality, and expediency. Making a complaint in good faith will not result in victimisation.</li>
</ol>
</li>
<li><strong>Disciplinary Actions</strong><ol>
<li>Violations of this policy, including acts of discrimination, harassment, or bullying, may lead to disciplinary action, up to and including dismissal for gross misconduct.</li>
<li>Allegations not made in good faith may also result in disciplinary measures.</li>
</ol>
</li>
<li><strong>Policy Review</strong><ol>
<li>The Employer commits to regularly reviewing this policy and associated practices to ensure their effectiveness in promoting equality and equal opportunities.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-dignity-at-work-policy-incorporating-bullying-and-harassment-"><strong>Dignity at Work Policy (Incorporating Bullying and Harassment)</strong></h2>
<ul>
<li><ol>
<li><strong>Policy statement on dignity at work</strong><ol>
<li>The Employer is dedicated to ensuring all staff are treated with dignity and respect and are expected to treat others similarly.</li>
<li>This policy covers all forms of harassment and bullying, whether in the workplace or in work-related contexts such as business trips, customer or supplier events, or social functions.</li>
<li>It applies to all personnel, including employees, managers, contractors, consultants, volunteers, and agency staff.</li>
</ol>
</li>
<li><strong>Understanding Unacceptable Behaviours</strong><ol>
<li>All staff must understand what constitutes unacceptable behaviour. Unacceptable behaviours include, but are not limited to:<ol>
<li>All staff must understand what constitutes unacceptable behaviour. Unacceptable behaviours include, but are not limited to:</li>
<li>Offensive jokes or comments, including remarks about a person&#39;s age, disability, gender, or other protected characteristics,</li>
<li>Displaying offensive materials,</li>
<li>Isolation, non-cooperation or exclusion from work-related activities</li>
<li>Coercion for sexual favours,</li>
<li>Intrusive behaviours such as pestering, spying, or stalking.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Harassment</strong><ol>
<li>Harassment occurs when a staff member is subjected to unwanted conduct that violates their dignity or creates an intimidating, hostile, degrading, humiliating, or offensive environment.</li>
<li>This includes sexual harassment and other forms of inappropriate behavior.</li>
<li>The key aspect of harassment is how the conduct makes the recipient feel; the intention of the perpetrator is irrelevant.</li>
</ol>
</li>
<li><strong>Bullying</strong><ol>
<li>Bullying is offensive, intimidating, malicious, or insulting behaviour, which undermines, humiliates, denigrates, or injures the recipient.</li>
<li>This can be physical, verbal, or non-verbal. Examples include:<ol>
<li>Spreading malicious rumours,</li>
<li>Unfair treatment,</li>
<li>Overbearing supervision or other misuse of power.</li>
</ol>
</li>
<li><strong>Reporting Procedures</strong><ol>
<li><em>The company takes a proactive stance in addressing issues of harassment and bullying:</em></li>
</ol>
</li>
<li><strong>Informal Procedure</strong><ol>
<li>If you experience harassment or bullying, you are encouraged to address the issue informally at first.</li>
<li>This may involve directly speaking to the person involved, or, if uncomfortable, seeking help from a manager or trusted colleague to facilitate a discussion.</li>
</ol>
</li>
<li><strong>Formal Procedure</strong><ol>
<li>If informal resolution is not feasible or the behaviour persists, a formal complaint should be made to your line manager, or another manager if the line manager is involved.</li>
<li>Include all relevant details such as the nature of the incident, dates, and times, and any witnesses.</li>
<li>Investigations will be conducted promptly and impartially, with both the complainant and the alleged perpetrator given the opportunity to present their side of the story.</li>
<li>Measures may be taken to ensure that individuals involved are separated during the investigation.</li>
</ol>
</li>
<li><strong>Outcome of Investigations</strong><ol>
<li>Following an investigation, appropriate action will be taken. If the complaint is upheld, disciplinary action up to and including dismissal may be warranted.</li>
<li>If the complaint is not upheld, steps may be taken to manage the working relationship between the parties involved to prevent further conflicts.</li>
</ol>
</li>
<li><strong>Protection Against Retaliation</strong><ol>
<li>Retaliation against an individual who has made a complaint in good faith is a serious violation of this policy and will be treated as a disciplinary matter.</li>
<li>Conversely, allegations of harassment or bullying that are found to have been made maliciously will be dealt with under the disciplinary policy.</li>
</ol>
</li>
<li><strong>Commitment to Training</strong><ol>
<li>Staff will receive training on this policy to ensure everyone understands their rights and responsibilities regarding maintaining dignity at work.</li>
<li>Managers and supervisors will receive additional training to handle reported incidents effectively.</li>
</ol>
</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-disciplinary-procedure-incorporating-disciplinary-rules-"><strong>Disciplinary Procedure (incorporating Disciplinary Rules)</strong></h2>
<ul>
<li><ol>
<li><strong>Scope and Status</strong><ol>
<li>This Disciplinary Procedure applies to all employees of MT SPV Limited, regardless of their role, seniority, or length of service. This procedure addresses misconduct in all locations and situations where the company operates, including off-site locations and social functions, and in some instances, off-duty behaviour that affects the company&#39;s interests.</li>
<li>This Procedure does not form part of any employee&#39;s contract of employment. The terms may be amended by the Employer from time to time at its sole and absolute discretion.</li>
<li>This Procedure may be used where there are apparent issues of misconduct. It does not apply to cases where an employee fails to perform to the required standard as a result of lack of skill, capability or training or where there are genuine sickness absence issues. In those cases, reference should be made to the Capability Procedure.</li>
</ol>
</li>
<li><strong>Policy statements</strong><ol>
<li>This procedure aligns with ACAS Code of Practice 1: Discipline and Grievance. It aims to ensure fairness and consistency in the disciplinary process, and is coordinated with agreements from unions like Equity, SOLT, BECTU, and the Musicians Union. When discrepancies arise between this procedure and union-specific policies, union policies will prevail. These guidelines are not contractual and may be amended by the employer.</li>
<li>No employee will be the subject of disciplinary action under this Procedure without an appropriate investigation having been carried out and the employee given a proper opportunity to consider and respond to the matters alleged.</li>
<li>All employees will have the right to appeal against any formal disciplinary sanction imposed upon them.</li>
<li>Save in exceptional circumstances, such as those of gross misconduct, no employee will be dismissed for a first offence.</li>
<li>All disciplinary processes including investigations, meetings and confirmations of outcomes will be carried out promptly having regard to all the circumstances of the case.</li>
<li>All those involved in a disciplinary process will respect the confidentiality and privacy of others. Information will only be disclosed by the Employer to the extent necessary to ensure that the objectives of this Procedure are met.</li>
<li>Where disciplinary action is being considered against an employee who is a trade union representative, it is likely that the Employer will discuss the matter at an early stage with an official employed by the union (after obtaining the employee&#39;s agreement).</li>
</ol>
</li>
<li><strong>Procedure Guidelines</strong><ol>
<li><strong>Investigation</strong><ol>
<li>Upon any allegations of misconduct, a designated manager will investigate promptly to establish facts, gathering evidence and witness statements as necessary. The employee in question may be required to attend an investigatory meeting.</li>
</ol>
</li>
<li><strong>Outcome of Investigation</strong>:<ol>
<li>Depending on findings, actions may range from no case, informal actions like coaching, to initiating a Disciplinary Hearing.</li>
</ol>
</li>
<li><strong>Suspension</strong>:<ol>
<li>In cases involving serious allegations, suspension with pay may be necessary to protect the integrity of the investigation. This is not considered a disciplinary action, and regular reviews will be conducted.</li>
</ol>
</li>
<li><strong>Disciplinary Hearing</strong>:<ol>
<li>If warranted, the employee will receive at least 48 hours&#39; notice of a Disciplinary Hearing, where they can defend against the allegations with the option to be accompanied by a union representative or fellow employee.</li>
</ol>
</li>
<li><strong>Decisions</strong>:<ol>
<li>Post-hearing, decisions will be communicated promptly, ranging from no action, various warnings, to dismissal in cases of gross misconduct.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Disciplinary Actions</strong><ol>
<li><strong>Written Warnings</strong>:<ol>
<li>For general misconduct, a written warning may be issued detailing required behavioural changes, with a clear timeline for improvement.</li>
</ol>
</li>
<li><strong>Final Written Warning</strong>:<ol>
<li>Issued for more severe misconduct or repeated offences, indicating that further misconduct may lead to dismissal.</li>
</ol>
</li>
<li><strong>Dismissal</strong>:<ol>
<li>Applied in cases of severe breaches, either with notice or as summary dismissal (immediate without notice) in cases of gross misconduct.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Right of Appeal</strong><ol>
<li>Employees have the right to appeal any formal disciplinary action. Appeals will be heard by a senior manager or director not previously involved, ensuring an impartial review of the decision.</li>
</ol>
</li>
<li><strong>Right to be Accompanied</strong><ol>
<li>Employees are entitled to have a companion at any disciplinary or appeal hearing, who may speak on behalf of the employee but not answer questions directed to the employee.</li>
</ol>
</li>
<li><strong>Disciplinary Rules</strong><ol>
<li>Misconduct is defined as behaviour seriously inconsistent with the duties and obligations of employment, including but not limited to:<ol>
<li>Unauthorised absence</li>
<li>Insubordination</li>
<li>Misuse of company property</li>
</ol>
</li>
<li>Breach of company policies Gross misconduct includes actions such as theft, fraud, physical violence, serious discrimination, or other acts that breach fundamental contractual obligations.</li>
</ol>
</li>
<li><strong>Agreed Outcome</strong><ol>
<li>At any stage, an employee may propose an agreed resolution to the disciplinary issue, which, if accepted by management, concludes the matter without a hearing.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-grievance-procedure-"><strong>Grievance Procedure</strong></h2>
<ul>
<li><ol>
<li><strong>Purpose and scope of the procedure</strong><ol>
<li>This procedure is designed to address concerns, problems, or complaints that employees might have with their employment or workplace conditions.</li>
<li>MT SPV Limited encourages employees to initially address grievances informally with their immediate supervisor. If unresolved, the grievance should be pursued formally according to this procedure.</li>
</ol>
</li>
<li><strong>Applicability</strong><ol>
<li>This procedure applies to all employees.</li>
<li>It does not extend to agency workers, consultants, contractors, volunteers, interns, or casual workers. While aligned with agreements from Equity, SOLT, BECTU, and the Musicians Union, any discrepancies favour the union&#39;s procedure, though these guidelines are not part of the employment contract and may be adjusted as necessary.</li>
</ol>
</li>
<li><strong>Formal Grievance Process</strong><ol>
<li><strong>Stage One: Written Grievance</strong><ol>
<li>If an issue cannot be resolved informally or if formal action is appropriate, submit a written grievance to your line manager without unreasonable delay. If your grievance involves your line manager, escalate to a senior manager.</li>
<li>Your grievance should detail the nature of your complaint, including relevant facts, dates, and names involved.</li>
<li>A meeting will be scheduled, usually within five working days, to discuss the grievance. You may bring a trade union representative or a colleague as a companion if requested in advance.</li>
<li>Following the meeting, a decision will be communicated within five working days, with details on any actions to be taken and your right to appeal. If more time is needed, you will be notified of a new timeline.</li>
</ol>
</li>
<li><strong>Stage Two: Meeting</strong><ol>
<li>This stage involves discussing the grievance in detail, where further explanations about the issue and proposed resolutions can be presented.</li>
<li>If further investigation is necessary, the meeting may be adjourned and reconvened later.</li>
<li>A decision will be confirmed in writing shortly after the meeting concludes, detailing any actions and advising on the appeal process.</li>
</ol>
</li>
<li><strong>Stage Three: Appeal</strong><ol>
<li>If the grievance remains unresolved, you can appeal in writing to your Managing Director within five working days of receiving the decision.</li>
<li>An appeal hearing will be arranged, conducted by a senior manager not previously involved. The outcome of this hearing is final.</li>
<li>You will receive a written response within five working days of the appeal hearing. There is no further right of appeal.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Right to Be Accompanied</strong><ol>
<li>At any stage of the formal process, employees have the right to be accompanied by a colleague or a union representative.</li>
<li>This companion may speak on behalf of the employee but not answer questions directed at the employee.</li>
</ol>
</li>
<li><strong>General Principles</strong><ol>
<li>All grievances will be treated with confidentiality, promptness, and sensitivity.</li>
<li>The procedure aims to ensure fair treatment for all parties involved and to uphold the integrity of the workplace relationships<strong>.</strong></li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-performance-and-capability-at-work-"><strong>Performance and Capability at Work</strong></h2>
<ol>
<li><strong>Purpose and Scope</strong><ol>
<li>This document outlines the policy and procedures MT SPV Limited employs to manage:</li>
<li><strong>Poor Performance</strong>: Issues arising from lack of skill or aptitude in performing job duties.</li>
<li><strong>Persistent Short-term Absence</strong>: Ongoing intermittent attendance issues.</li>
<li>This policy applies to all employees, excluding agency workers, consultants, self-employed contractors, volunteers, interns, and casual workers. While it may be amended at any time, this policy is not part of any employment contract.</li>
</ol>
</li>
<li><strong>Procedure</strong><ol>
<li><strong>Initial Steps</strong><ol>
<li>If you are experiencing difficulties in performance or attendance, you are encouraged to discuss these informally with your line manager to seek a resolution.</li>
<li>If issues persist, or are of a serious nature, they should be addressed formally through this procedure.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Data Protection</strong><ol>
<li>All personal data, especially concerning health, will be handled in compliance with applicable data protection laws.</li>
</ol>
</li>
<li><strong>Formal Procedure</strong><ol>
<li><strong>Written Notification</strong><ol>
<li>If informal resolution is not possible, submit a written grievance outlining the specific issues, including relevant facts and dates, to your line manager or a senior manager if it involves your line manager.</li>
</ol>
</li>
<li><strong>Meeting</strong><ol>
<li>A meeting will be arranged to discuss the issues, typically within five working days of receiving your written notification. You are entitled to be accompanied by a colleague or union representative.</li>
<li>The meeting may involve discussing potential causes, exploring solutions, and outlining possible consequences if insufficient improvement is noted.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Improvement Notice</strong><ol>
<li>If deemed necessary, an improvement notice may be issued, which includes a Performance Improvement Plan (PIP). This notice will outline required improvements, support provided, timelines for review, and potential consequences of non-compliance.</li>
<li>Performance and/or attendance will be monitored throughout the review period specified in the PIP.</li>
</ol>
</li>
<li><strong>Review and Further Action</strong><ol>
<li>Following the review period, your progress will be assessed. If satisfactory improvement is noted, no further action will be taken.</li>
<li>If improvements are not satisfactory, further actions, including additional notices or final warnings, may be issued.</li>
</ol>
</li>
<li><strong>Final Written Warning</strong><ol>
<li>If performance or attendance issues continue beyond initial interventions, a final written warning may be issued. This will reiterate improvement requirements, consequences for non-compliance, and will include a final review period.</li>
<li><strong>Dismissal or Other Sanctions</strong><ol>
<li>Should there be no satisfactory improvement after a final written warning, or if initial misconduct is sufficiently serious, dismissal or other disciplinary actions such as demotion or changes in duties may be considered.</li>
<li>Any decision to dismiss will involve a formal meeting and will be taken only by an authorised manager.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Capability Meetings</strong><ol>
<li>Meetings under this procedure will detail the issues to be discussed, potential consequences, and your right to be accompanied.</li>
<li>You will have the opportunity to present your case, respond to evidence, and propose solutions during the meeting.</li>
</ol>
</li>
<li><strong>Appeal</strong><ol>
<li>If you disagree with a capability decision, you may appeal in writing within five working days of receiving the decision. The appeal should specify the grounds and any new evidence you wish to present.</li>
<li>The appeal will be heard by a manager or director not previously involved, to ensure impartiality.</li>
</ol>
</li>
</ol>
<h1 id="family-and-dependents-policies-and-procedures">FAMILY AND DEPENDENTS POLICIES AND PROCEDURES</h1>
<h2 id="-antenatal-appointments-birth-parent-or-companions-"><strong>Antenatal appointments (birth parent or companions)</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy outlines the entitlements and procedures for pregnant employees and eligible companions regarding time off for antenatal appointments. It applies to all employees and, under certain conditions, to agency workers who have completed 12 weeks of qualifying work.</li>
</ol>
</li>
<li><strong>Scope</strong><ol>
<li>This policy does not extend to consultants or casual workers and may be amended at any time by the employer. It is not part of the employment contract.</li>
</ol>
</li>
<li><strong>Time Off for Antenatal Care</strong><ol>
<li><strong>Pregnant Employees:</strong> All pregnant employees are entitled to paid time off for antenatal care as recommended by a doctor, midwife, or health visitor. This includes medical appointments and, potentially, classes such as relaxation or parenting, provided they come recommended by a healthcare professional.</li>
<li><strong>Notification Requirements:</strong> You must inform your employer about the timing of appointments as soon as possible. After the first appointment, you may be required to show proof of the appointment and provide a MAT B1 form confirming your expected week of childbirth.</li>
</ol>
</li>
<li><strong>Scheduling Appointments</strong><ol>
<li>Try to schedule appointments at the start or end of the workday to minimise disruption, and discuss timing with your line manager where possible.</li>
</ol>
</li>
<li><strong>Time Off to Accompany an Expectant Mother</strong><ol>
<li><strong>Eligibility:</strong> Employees who are the husband, civil partner, live-in partner (not close relatives), biological father, or part of a surrogacy arrangement intending to apply for a parental order may take unpaid time off to accompany a pregnant woman to up to two antenatal appointments per pregnancy.</li>
<li><strong>Duration:</strong> Each instance of time off can last up to six and a half hours, including travel and waiting times.</li>
</ol>
</li>
<li><strong>Procedure to Accompany:</strong><ol>
<li><strong>Notification:</strong> Inform your employer as soon as possible about the appointment details, intended start and end times of your time off, and any previous time off taken for antenatal appointments for the same pregnancy.</li>
<li><strong>Proof Requirements:</strong> If requested, you must provide a signed declaration detailing your relationship with the pregnant woman or expected child, the purpose of the appointment, confirmation that the appointment is medically advised, and the date and time of the appointment.</li>
</ol>
</li>
<li><strong>Considerations for Scheduling:</strong><ol>
<li>When possible, try to arrange accompanying appointments at the beginning or end of the workday, in consultation with your line manager.</li>
</ol>
</li>
</ol>
<h2 id="-flexible-working-"><strong>Flexible Working</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This document outlines MT SPV Limited&#39;s policy for handling requests by eligible employees for variations to their terms and conditions of employment, specifically relating to flexible working arrangements.</li>
<li><strong>Scope</strong><ol>
<li>This policy applies to all employees and, under specific conditions, to agency workers who have completed 12 weeks of qualifying work. It does not apply to consultants, contractors, volunteers, interns, or casual workers. The policy is not part of the employment contract and may be amended at any time.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Eligibility Conditions</strong><ol>
<li>To be eligible to make a flexible working request, you must:<ol>
<li>Be an employee.</li>
<li>Have been continuously employed by us for at least 26 weeks at the date your request is made.</li>
<li>Not have made a flexible working request in the previous 12 months.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Flexible Working Requests</strong><ol>
<li>Flexible working requests may include:<ol>
<li>Changes to working hours (e.g., reduction in hours per week).</li>
<li>Changes to the times you are required to work (e.g., different days or altered start/end times).</li>
<li>Requests to work from home or another business location for all or part of the work week.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Making a Flexible Working Request</strong><ol>
<li>Submit your request in writing to your Line Manager, clearly stating that it is a flexible working request.</li>
<li>Include details of the desired changes, the proposed start date, potential impacts on the business, and how these might be managed.</li>
<li>Specify any previous flexible working requests made and disclose if the request is related to disability accommodations.</li>
</ol>
</li>
<li><strong>Process</strong><ol>
<li><strong>Initial Meeting</strong>: We will arrange a meeting within 28 days of receiving your request to discuss it. You may bring a colleague of your choice to this meeting.</li>
<li><strong>Decision</strong>: If we decide to grant the request without a meeting, we will confirm this in writing. Otherwise, we will communicate the decision after the meeting, usually within five working days.</li>
<li><strong>Trial Period</strong>: We may propose a trial period to assess the viability of the requested changes before making a final decision.</li>
<li><strong>Outcome</strong>: If your request is approved, we will issue a written confirmation detailing the new working arrangement and its start date. If modifications are necessary, these will be discussed and agreed upon.</li>
<li><strong>Rejection</strong>: If we cannot accommodate your request, we will explain the business reasons and provide details on how to appeal.</li>
</ol>
</li>
<li><strong>Appeal</strong><ol>
<li>If you wish to appeal the decision, submit a written appeal within 14 days detailing the grounds of your appeal.</li>
<li>A meeting will be arranged to discuss the appeal, and a final decision will be provided in writing within 14 days of the appeal meeting.</li>
</ol>
</li>
<li><strong>Withdrawal</strong><ol>
<li>You may withdraw your request at any time. If a meeting is missed without reasonable cause, the request may be considered withdrawn.</li>
<li>To officially withdraw, notify us in writing.</li>
</ol>
</li>
</ol>
<h2 id="-maternity-"><strong>Maternity</strong></h2>
<ul>
<li><ol>
<li><strong>Introduction</strong><ol>
<li>This policy outlines MT SPV Limited&#39;s policy for supporting pregnant employees and those who have recently given birth. It covers arrangements for pregnancy-related sickness, health and safety, maternity leave, and pay.</li>
</ol>
</li>
<li><strong>Scope</strong><ol>
<li>This policy applies to all employees. Health and safety aspects also extend to qualifying agency workers. Consultants and self-employed contractors are not covered by this policy. Agency workers should contact their respective agencies for maternity-related entitlements.</li>
</ol>
</li>
<li><strong>Maternity Leave</strong><ol>
<li><strong>Entitlement</strong>: All pregnant employees are entitled to 52 weeks of maternity leave, split into 26 weeks of Ordinary Maternity Leave (OML) and 26 weeks of Additional Maternity Leave (AML).</li>
<li><strong>Compulsory Leave</strong>: You must take at least two weeks of maternity leave immediately following childbirth.</li>
</ol>
</li>
<li><strong>Notification Requirements</strong><ol>
<li><strong>Timing</strong>: Notify us by the 15th week before your Expected Week of Childbirth (EWC), indicating when you wish to start your maternity leave.</li>
<li><strong>Documentation</strong>: Provide a MAT B1 form from your healthcare provider to confirm your EWC.</li>
</ol>
</li>
<li><strong>Pregnancy-related Sickness</strong><ol>
<li>If you&#39;re absent due to pregnancy-related issues within four weeks of your EWC, your maternity leave will commence automatically.</li>
</ol>
</li>
<li><strong>Health and Safety</strong><ol>
<li><strong>Notification</strong>: Inform us immediately upon confirming your pregnancy so we can conduct a necessary risk assessment.</li>
<li><strong>Post-Birth</strong>: Notify us if you return to work within six months of birth or if breastfeeding, to reassess health and safety measures.</li>
</ol>
</li>
<li><strong>Starting Maternity Leave</strong><ol>
<li><strong>Timing</strong>: Maternity leave can start anytime from the 11th week before your EWC.</li>
<li><strong>Early Birth</strong>: If you give birth before your Planned Start Date, notify us as soon as practicable, and your leave will start the following day.</li>
</ol>
</li>
<li><strong>Maternity Pay</strong><ol>
<li><strong>Statutory Maternity Pay (SMP)</strong>: Eligible for up to 39 weeks. You must have been employed for at least 26 weeks by the 15th week before your EWC and meet earnings criteria.</li>
<li><strong>Payment</strong>: During the first six weeks, receive 90% of your average weekly earnings. Thereafter, receive either the statutory rate or 90% of your earnings, whichever is lower.</li>
<li><strong>Maternity Allowance (MA)</strong>: If you do not qualify for SMP, you may be eligible for MA, which is managed through Jobcentre Plus.</li>
</ol>
</li>
<li><strong>During Maternity Leave</strong><ol>
<li><strong>Contractual Benefits</strong>: Continue to accrue except for wages.</li>
<li><strong>Annual Leave</strong>: Accrues throughout your maternity leave. Discuss with your manager if you need to carry over more than one week&#39;s holiday.</li>
<li><strong>Pension Contributions</strong>: Employer contributions will continue based on your normal salary during paid leave. Employee contributions will be adjusted according to maternity pay received.</li>
</ol>
</li>
<li><strong>Keeping in Touch</strong><ol>
<li><strong>KIT Days</strong>: Up to 10 days during maternity leave for work-related activities, which do not end your maternity leave or SMP.</li>
</ol>
</li>
<li><strong>Returning to Work</strong><ol>
<li><strong>Notice</strong>: Provide at least eight weeks&#39; notice if returning to work earlier than your Expected Return Date.</li>
<li><strong>Late Return</strong>: If delayed beyond your Expected Return Date without sufficient cause, this may be treated as unauthorised absence.</li>
</ol>
</li>
<li><strong>Post-Maternity Leave</strong><ol>
<li><strong>Return</strong>: Typically, return to your original job on the same terms. If impractical after AML, an alternative job will be offered.</li>
<li><strong>Flexible Working</strong>: Consider requesting changes under our Flexible Working Policy.</li>
</ol>
</li>
<li><strong>Resignation</strong><ol>
<li>If you choose not to return to work, notify us as soon as possible, adhering to your contractual notice period.</li>
</ol>
</li>
</ol>
</li>
</ul>
<h2 id="-parental-leave-"><strong>Parental Leave</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy outlines the rights and responsibilities of MT SPV Limited employees who wish to take parental leave, which is available to parents of both natural and adopted children.</li>
</ol>
</li>
<li><strong>Scope</strong><ol>
<li>This policy applies to all employees. It does not apply to agency workers, consultants, or self-employed contractors. The policy provides guidance only and is not part of the employment contract, subject to change at the employer’s discretion.</li>
</ol>
</li>
<li><strong>Entitlement to Parental Leave</strong><ol>
<li><strong>Eligibility</strong>: To be eligible for parental leave, you must have one year’s continuous employment with the company, have parental responsibility for a child under 18, and be taking leave to care for that child.</li>
<li><strong>Parental Responsibility</strong>: You are considered to have parental responsibility if you are the child’s biological or adoptive parent, the father registered on the birth certificate, or a legally recognized guardian.</li>
</ol>
</li>
<li><strong>Notification Requirements</strong><ol>
<li><strong>General Notification</strong>: Inform your line manager at least 21 days before your intended start date of leave, specifying the start and end dates.</li>
<li><strong>Birth/Adoption</strong>: If taking leave immediately after childbirth or adoption, notify at least 21 days before the expected week of childbirth or placement. If unforeseen circumstances prevent this, notify as soon as possible.</li>
<li><strong>Documentation</strong>: You may be required to provide a birth certificate, adoption papers, or other proof of parental responsibility.</li>
</ol>
</li>
<li><strong>Taking Parental Leave</strong><ol>
<li><strong>Duration</strong>: Eligible employees can take up to 18 weeks of unpaid leave per child, applicable until the child turns 18.</li>
<li><strong>Conditions</strong>: Leave is typically taken in blocks of one week or multiples, with a maximum of four weeks per year for each child. Exceptions apply for disabled children, allowing for more flexible scheduling.</li>
<li><strong>Postponement</strong>: The employer may postpone leave for up to six months if it would cause undue disruption to the business, except when leave is taken immediately after childbirth or adoption, or postponement would push the leave beyond the child’s 18th birthday.</li>
</ol>
</li>
<li><strong>During Parental Leave</strong><ol>
<li><strong>Contractual Benefits</strong>: While salary is not paid, other contractual benefits continue unless specified otherwise.</li>
<li><strong>Annual Leave</strong>: Accrues during parental leave and may need to be taken immediately before or after the leave if it extends into the next holiday year.</li>
<li><strong>Pension Contributions</strong>: Employer contributions to pension schemes are paused during unpaid parental leave.</li>
</ol>
</li>
<li><strong>Returning to Work</strong><ol>
<li><strong>Same Position</strong>: Typically, if parental leave is four weeks or less, you will return to the same position with no less favourable terms.</li>
<li><strong>Extended Leave</strong>: For longer or combined leaves, if returning to the same position is not feasible, an alternative suitable position will be offered.</li>
<li><strong>Notice of Return</strong>: Provide at least eight weeks&#39; notice if you plan to return to work earlier than planned.</li>
</ol>
</li>
<li><strong>Breaches of Policy</strong><ol>
<li><strong>Misuse of Leave</strong>: Parental leave is specifically for child care. Misuse of leave for other purposes may result in disciplinary action under the company’s Disciplinary Procedure.</li>
</ol>
</li>
</ol>
<h2 id="-paternity-leave-"><strong>Paternity Leave</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy sets out the entitlements to paternity leave and pay for MT SPV Limited employees.</li>
<li>This guidance applies to fathers and partners of the mother or adopter and includes provisions for both natural and adopted children.</li>
</ol>
</li>
<li><strong>Scope</strong><ol>
<li>This policy is applicable to all permanent employees but does not extend to agency workers, consultants, or self-employed contractors.</li>
<li>It serves as a summary of your legal rights and is not part of your employment contract.</li>
</ol>
</li>
<li><strong>Eligibility for Paternity Leave</strong><ol>
<li><strong>Employment Duration</strong>: You must have completed at least 26 weeks of continuous employment by the 15th week before the baby is due or the week you are matched with your adopted child.</li>
<li><strong>Parental Responsibility</strong>: You must be:<ol>
<li>The biological father of the child,</li>
<li>The partner of the child’s mother,</li>
<li>The child&#39;s adopter, or</li>
<li>The intended parent in a surrogacy arrangement.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Paternity Leave</strong><ol>
<li><strong>Duration</strong>: Eligible employees can take either one week or two consecutive weeks of leave.</li>
<li><strong>Timing</strong>: Leave can begin on the child&#39;s birth date, the date of the child&#39;s placement for adoption, or a chosen date after either event but must be completed within 56 days of the birth or placement.</li>
</ol>
</li>
<li><strong>Notification Requirements</strong><ol>
<li><strong>Advance Notice</strong>: You must inform your line manager at least 15 weeks before the baby is expected or the expected week of the adoption placement.</li>
<li><strong>Written Notice</strong>: Provide written notice indicating the expected week of childbirth or adoption, the duration of paternity leave sought, and the proposed start date.</li>
<li><strong>Documentation</strong>: You may be required to provide a copy of the child&#39;s birth certificate, adoption papers, or proof of your responsibility for the child.</li>
</ol>
</li>
<li><strong>Paternity Pay</strong><ol>
<li><strong>Statutory Paternity Pay (SPP)</strong>: If eligible, you will receive SPP either at a fixed rate set by the government or 90% of your average weekly earnings, whichever is lower, for up to two weeks.</li>
<li><strong>Qualifying Conditions</strong>: To qualify for SPP, you must earn at least the lower earnings limit and provide the necessary documentation and notice.</li>
</ol>
</li>
<li><strong>During Paternity Leave</strong><ol>
<li><strong>Contractual Benefits</strong>: You will continue to accrue annual leave and receive other contractual benefits, except for wages or salary.</li>
<li><strong>Pension Contributions</strong>: Employer contributions to pension schemes will continue based on your normal salary.</li>
</ol>
</li>
<li><strong>Returning to Work</strong><ol>
<li><strong>Reinstatement</strong>: You are entitled to return to the same job on the same terms and conditions after paternity leave.</li>
<li><strong>Combined Leave</strong>: If paternity leave is combined with other family-related leaves, different rules may apply regarding your return position.</li>
</ol>
</li>
<li><strong>Changes to Paternity Leave</strong><ol>
<li><strong>Adjusting Dates</strong>: If you need to change the start date, provide at least 28 days&#39; notice before the new start date or as soon as practicable.</li>
</ol>
</li>
<li><strong>Non-Return to Work</strong><ol>
<li><strong>Resignation</strong>: If you decide not to return to work, you must provide notice as per your employment contract. Ensure you have sufficient leave remaining to cover your notice period, as returning to work for the notice period may be required.</li>
</ol>
</li>
</ol>
<h2 id="-shared-parental-leave-"><strong>Shared Parental Leave</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy provides guidance on shared parental leave (SPL) and pay available to employees following the birth or adoption of a child. This policy is applicable to all employees of MT SVP Limited. It details the rights under the law but is not exhaustive.</li>
</ol>
</li>
<li><strong>Scope</strong><ol>
<li>This policy applies to employees, and aspects of shared parental pay also extend to those in &#39;employed earner&#39;s employment&#39;. It does not apply to non-employees such as consultants or self-employed contractors.</li>
</ol>
</li>
<li><strong>Definitions</strong><ol>
<li><strong>Expected Week of Childbirth (EWC):</strong> The week, starting on a Sunday, when your child is expected to be born.</li>
<li><strong>Parent:</strong> One of two people sharing the main responsibility for the child&#39;s upbringing, including the mother, father, or the mother’s partner.</li>
<li><strong>Partner:</strong> Your spouse, civil partner, or someone living with you in an enduring family relationship, excluding close relatives.</li>
</ol>
</li>
<li><strong>Shared Parental Leave (SPL)</strong><ol>
<li>SPL allows parents to share up to 50 weeks of leave and 37 weeks of pay if it is not used entirely by the child’s mother. SPL can be taken concurrently or separately but must be used within one year of the child’s birth or placement for adoption.</li>
</ol>
</li>
<li><strong>Eligibility</strong> To qualify for SPL:<ol>
<li>You must have at least 26 weeks of continuous employment by the end of the 15th week before the EWC.</li>
<li>You must share child care responsibility with a partner who meets similar employment and earnings criteria.</li>
</ol>
</li>
<li><strong>Notification Requirements</strong><ol>
<li><strong>Notice:</strong> Inform your line manager in writing at least 15 weeks before the EWC. Specify the expected week of childbirth, the leave duration, and start date.</li>
<li><strong>Documentation:</strong> You may be required to provide the child’s birth certificate, adoption documentation, or proof of responsibility for the child.</li>
</ol>
</li>
<li><strong>How to Apply for SPL</strong><ol>
<li><strong>Mother’s Curtailment Notice:</strong> The mother must end her maternity leave or pay entitlements to enable SPL. This notice must be given at least eight weeks before the desired start date.</li>
<li><strong>Notice of Entitlement:</strong> Both parents must provide a notice stating their entitlement and intention to take SPL.</li>
</ol>
</li>
<li><strong>Taking SPL</strong><ol>
<li>SPL can be taken in blocks agreed upon with the employer, with a minimum duration of one week.</li>
<li>Leave must be taken within 56 days of the birth or placement unless otherwise agreed.</li>
</ol>
</li>
<li><strong>Paternity Leave and SPL</strong><ol>
<li>Paternity leave is distinct from SPL and must be taken before SPL. The former additional paternity leave rights have been replaced by SPL.</li>
</ol>
</li>
<li><strong>Shared Parental Pay (SPP)</strong><ol>
<li>SPP is available for up to 37 weeks, paid at the statutory rate or 90% of your average weekly earnings, whichever is lower.</li>
<li>The eligibility for SPP is similar to SPL but includes earnings thresholds.</li>
</ol>
</li>
<li><strong>During SPL</strong><ol>
<li>You will continue to accrue annual leave and other benefits.</li>
<li>Employer contributions to pension schemes will continue based on normal salary during paid SPL.</li>
</ol>
</li>
<li><strong>Returning to Work</strong><ol>
<li>You are entitled to return to the same job or an equivalent role if it’s not feasible to return to the original position after SPL.</li>
<li>Notice of return should be given at least eight weeks before the intended return date.</li>
</ol>
</li>
<li><strong>Changes to Arrangements</strong><ol>
<li>Any changes to the agreed SPL arrangement must be notified at least eight weeks in advance.</li>
</ol>
</li>
</ol>
<h2 id="-time-off-for-dependants-"><strong>Time Off for Dependants</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy outlines the statutory right that employees have to take unpaid time off work to deal with unexpected events affecting their dependents.</li>
<li>It applies to all employees, officers, agency workers, and consultants of MT SPV Limited.</li>
</ol>
</li>
<li><strong>Overlap with Other Policies</strong><ol>
<li>This policy should be read in conjunction with the Employer&#39;s Compassionate Leave Policy.</li>
<li>There may be overlap; however, you may only exercise your right under one policy for a particular family member in similar circumstances.</li>
</ol>
</li>
<li><strong>Policy Details</strong><ol>
<li><strong>Leave Entitlement:</strong> Employees are entitled to take a reasonable amount of time off during working hours to deal with necessary actions due to the unexpected:<ol>
<li>Illness, injury, or assault of a dependant.</li>
<li>Birth of a dependent.</li>
<li>Arrangements for care of an ill or injured dependant.</li>
<li>Death of a dependant.</li>
<li>Unexpected disruption/termination of care for a dependent.</li>
<li>An incident involving your child during school hours.</li>
</ol>
</li>
</ol>
</li>
<li><strong>Definition of a Dependant:</strong><ol>
<li>Spouse or civil partner.</li>
<li>Child or parent.</li>
<li>Any person living in the same household (excluding tenants, lodgers, employees).</li>
<li>Any person who relies on you for assistance in cases of illness or injury, or to make care arrangements.</li>
</ol>
</li>
<li><strong>Conditions for Leave:</strong><ol>
<li>Time off is only applicable to unexpected emergencies.</li>
<li>The duration should be reasonable to manage the immediate emergency and make necessary longer-term arrangements.</li>
<li>Leave for known situations or non-dependants must be covered under other leave policies such as annual or compassionate leave.</li>
</ol>
</li>
<li><strong>Notification Requirements:</strong><ol>
<li>Notify your line manager as soon as reasonably practicable about the reason for the leave and the expected duration.</li>
<li>Notification should ideally be done by telephone, or by email if outside normal working hours.</li>
<li>If circumstances change or continue beyond the initial notification, you must update your manager as soon as practicable.</li>
</ol>
</li>
<li><strong>Documentation:</strong><ol>
<li>Upon return, you may be required to provide written confirmation or evidence supporting the reason for your absence.</li>
</ol>
</li>
<li><strong>Pay:</strong><ol>
<li>Leave under this policy is unpaid.</li>
</ol>
</li>
<li><strong>Procedural Compliance:</strong><ol>
<li>Failure to comply with notification requirements or misuse of this policy may lead to disciplinary action.</li>
</ol>
</li>
</ol>
<h2 id="-anti-bribery-and-corruption-"><strong>Anti-Bribery and Corruption</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy outlines our commitment to combating bribery and corruption in compliance with the Bribery Act 2010.</li>
<li>This act is relevant to our operations both in the UK and internationally, emphasising the legal and ethical implications of bribery.</li>
<li>Our stance is clear: we operate with the highest standards of integrity and do not tolerate bribery or corruption in any form.</li>
</ol>
</li>
<li><strong>Policy Scope</strong><ol>
<li>This policy applies to all individuals working at or for MT SPV Limited, including employees at all levels, directors, officers, agency workers, contractors, consultants, and any other person associated with us.</li>
</ol>
</li>
<li><strong>Definitions</strong><ol>
<li><strong>Bribery:</strong> Involves offering, promising, giving, accepting, or soliciting something of value as an inducement for an action which is illegal or a breach of trust.</li>
<li><strong>Corruption:</strong> Involves improper actions to gain an advantage (e.g., altering data, misuse of authority) and can occur in both the public and private sectors.</li>
</ol>
</li>
<li><strong>Prohibitions</strong><ol>
<li>Engaging in any form of bribery or corruption.</li>
<li>Offering or accepting bribes, including facilitation payments.</li>
<li>Using third parties or channels to channel bribes to officials or private parties.</li>
</ol>
</li>
<li><strong>Gifts and Hospitality</strong><ol>
<li>Gifts over £100 in value or hospitality over £150 must be pre-approved and recorded.</li>
<li>Gifts of cash or cash equivalents are strictly prohibited.</li>
</ol>
</li>
<li><strong>Record-Keeping</strong><ol>
<li>Maintain accurate records of all financial transactions.</li>
<li>Declare and record all gifts and hospitality given or received.</li>
<li>Ensure transparency and compliance with all applicable financial and anti-money laundering regulations.</li>
</ol>
</li>
<li><strong>Responsibilities</strong><ol>
<li>All staff are responsible for avoiding any activities that could lead to, or imply, a breach of this anti-bribery policy.</li>
<li>Management is responsible for monitoring and enforcing compliance.</li>
</ol>
</li>
<li><strong>Reporting Violations</strong><ol>
<li>Report any suspicious behaviour or breach of this policy immediately.</li>
<li>Reports can be made anonymously and will be treated confidentially.</li>
</ol>
</li>
<li><strong>Disciplinary Actions</strong><ol>
<li>Non-compliance with this policy will lead to disciplinary action, up to and including termination of employment.</li>
<li>Legal action may also be taken in cases of corruption or bribery involving criminal activity.</li>
</ol>
</li>
<li><strong>Review and Monitoring</strong><ol>
<li>This policy will be reviewed regularly by the Managing Director to ensure its effectiveness and relevance.</li>
<li>Training on this policy will be provided as part of the induction process and regularly thereafter to ensure understanding and compliance.</li>
</ol>
</li>
<li><strong>Contact</strong><ol>
<li>For any queries or further information regarding this policy, please contact your line manager or the HR department.</li>
</ol>
</li>
</ol>
<h2 id="-sickness-and-attendance-"><strong>Sickness and Attendance</strong></h2>
<ol>
<li><strong>Introduction</strong><ol>
<li>This policy outlines the approach that the Employer takes to ensure regular attendance while recognizing that employees may sometimes fall ill.</li>
<li>The policy aims to manage such absences responsibly with the goal of facilitating the employee&#39;s return to work with appropriate support.</li>
</ol>
</li>
<li><strong>Applicability</strong><ol>
<li>This policy applies to all employees except agency workers, consultants, self-employed contractors, volunteers, interns, and casual workers.</li>
</ol>
</li>
<li><strong>Employee Responsibilities</strong><ol>
<li>Adhere to the Employer’s procedures for notification and certification of absence.</li>
<li>Maintain communication with the Employer during absences.</li>
<li>Comply with requests for medical or occupational health assessments.</li>
<li>Provide necessary information about your condition and cooperate in facilitating your return to work.</li>
</ol>
</li>
<li><strong>Notification and Certification</strong><ol>
<li>Notify your line manager as soon as possible on the first day of absence.</li>
<li>Continue to provide updates for absences longer than seven days.</li>
<li>For absences of up to seven days, complete a self-certification form upon return. For longer absences, provide a &#39;Fit Note&#39; from your GP or treating doctor.</li>
</ol>
</li>
<li><strong>Sick Pay</strong><ol>
<li>You may be entitled to statutory sick pay (SSP) as per the relevant regulations, which is not additional to any Employer sick pay.</li>
</ol>
</li>
<li><strong>Holiday and Absence</strong><ol>
<li>Holidays continue to accrue only to the minimum required by law during sickness absence.</li>
<li>Any excess holiday not taken due to sickness can be carried forward only under specific circumstances.</li>
</ol>
</li>
<li><strong>Return to Work</strong><ol>
<li>A return to work interview will be conducted to discuss your absence and fitness to return.</li>
<li>The Employer may conduct further medical assessments to ensure fitness for work.</li>
</ol>
</li>
<li><strong>Management of Attendance</strong><ol>
<li>Regular reviews may be conducted to assess and support your return to work.</li>
<li>Persistent short-term or long-term absences may lead to formal attendance reviews or application of the capability procedure, potentially leading to disciplinary action.</li>
</ol>
</li>
<li><strong>Medical Examinations and Reports</strong><ol>
<li>You may be required to undergo medical examinations and provide medical reports to support assessments regarding your fitness to work.</li>
</ol>
</li>
<li><strong>Adjustments for Disabled Employees</strong><ol>
<li>Reasonable adjustments will be considered to facilitate the return to work of disabled employees in compliance with the Equality Act 2010.</li>
</ol>
</li>
<li><strong>General</strong><ol>
<li>This policy is non-contractual and may be amended at any time by the Employer.</li>
<li>Specific union agreements or contracts may stipulate different terms regarding sick pay and leave entitlements.</li>
</ol>
</li>
</ol>
            </div>
        </StyledHandbook>
      </Layout>
    )
}

export default HandbookPage

export const HandbookQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "contact-us-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`